import React from 'react';
import { Link } from 'react-router-dom';
import isAvailable from 'alisto.js/lib/is-available';
import CategorySectionColumn from './CategorySectionColumn';
import CategorySectionRow from './CategorySectionRow';
import i18n from '../../../../javascript/js/i18n';

const CategorySection = function ({
  category, dispatch, menu, showGoBack,
}) {
  const categoryAvailable = isAvailable(category);

  return (
    <>
      <div className="mb-3">
        <h5 className="mb-1">
          {showGoBack && (
            <Link to={`/${i18n.routes.categories}`} className="btn my-auto">
              <i className="fas fa-arrow-left" />
            </Link>
          )}
          {category.name}
        </h5>
        {category.description && (
          <small>{category.description}</small>
        )}
        {!categoryAvailable && (
          <small className="d-block text-muted">
            {category.availability}
          </small>
        )}
      </div>
      {category.gridLayout === 'row' ? (
        <CategorySectionRow
          category={category}
          dispatch={dispatch}
          menu={menu}
        />
      ) : (
        <div className="row mb-3">
          <CategorySectionColumn
            category={category}
            dispatch={dispatch}
            menu={menu}
          />
        </div>
      )}
      {showGoBack && (
        <Link to={`/${i18n.routes.categories}`}>
          Voltar
        </Link>
      )}
    </>
  );
};

export default React.memo(CategorySection);
