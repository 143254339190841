import React from 'react';
import { Element } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import isAvailable from 'alisto.js/lib/is-available';
import isVisible from 'alisto.js/lib/is-visible';
import CategoryButton from './CategoryButton';
import CategorySection from './CategorySection';

const Category = function ({
  category, dispatch, menu, isSinglePage = true,
}) {
  if (isVisible(category)) {
    return isSinglePage ? (
      <Grid item xs={12}>
        <Element name={category.slug} className="category">
          <CategorySection
            category={category}
            dispatch={dispatch}
            menu={menu}
          />
        </Element>
      </Grid>
    ) : (
      <Grid item xs={6}>
        <CategoryButton
          category={category}
          categoryAvailable={isAvailable(category)}
        />
      </Grid>
    );
  }
  return '';
};

export default React.memo(Category);
