import persistToken from './persist-token';

export default (path) => {
  const dispatch = () => persistToken(path);

  if ('Notification' in window) {
    const permissionRequest = Notification.requestPermission();
    if (permissionRequest !== undefined) {
      return permissionRequest.then((permission) => {
        if (permission === 'granted') {
          dispatch();
        }
        return permission;
      });
    }
  }
  return Promise.resolve();
};
