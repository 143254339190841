import { withStyles } from '@material-ui/styles';
import ClickOnceButton from '../components/ClickOnceButton';

export default (color) => withStyles({
  root: {
    backgroundColor: color.main,
    color: color.contrastText,
    '&:hover': { backgroundColor: color.dark },
    '&:disabled': { backgroundColor: color.light },
  },
})(ClickOnceButton);
