import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ShowCategory = ({ categories, setSelectedCategory, isSinglePage }) => {
  const params = useParams();

  useEffect(() => {
    const selectedCategory = categories.find((item) => item.slug === params.slug);
    if (selectedCategory) {
      if (isSinglePage) {
        document.querySelector(`[name='${selectedCategory.slug}']`)?.scrollIntoView({ block: 'start' });
        window.scrollBy(0, -130);
      } else {
        setSelectedCategory(selectedCategory);
      }
    }
  });

  return '';
};

export default React.memo(ShowCategory);
