import React, {
  Suspense, lazy, useEffect, useState, useCallback, useMemo,
} from 'react';
import {
  HashRouter as Router, Routes, Route,
} from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Carousel } from 'react-responsive-carousel';
import SearchInput from 'alisto.js/lib/components/SearchInput';
import Header from './menu/Header';
import Category from './menu/Category';
import Product from './menu/Product';
import CategorySection from './menu/CategorySection';
import EnableNotifications from './EnableNotifications';
import Navigation from './menu/Navigation';
import ShowCategory from './menu/ShowCategory';
import ShowCategories from './menu/ShowCategories';
import ShowProduct from './menu/ShowProduct';
import i18n from '../../../javascript/js/i18n';
import 'core-js/features/array/flat';

window.global = globalThis;

const PWAPrompt = lazy(() => import('react-ios-pwa-prompt'));
const AdultsGate = lazy(() => import('./AdultsGate'));
const AddressFormFromState = lazy(() => import('./AddressFormFromState'));
const ItemDetailsWithNavigation = lazy(() => import('./ItemDetailsWithNavigation'));
const CustomerDialog = lazy(() => import('./CustomerDialog'));
const ShoppingCart = lazy(() => import('./ShoppingCart'));
const ChooseChannel = lazy(() => import('./shopping_cart/ChooseChannel'));

const Menu = function ({
  menu, dispatch, onSubmit, components, categories, isSignedIn,
}) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [showCustomerDialog, setShowCustomerDialog] = useState(false);
  const products = useMemo(() => {
    const toProducts = (category) => category.products;
    return categories.map(toProducts).flat();
  }, [categories]);
  const isSinglePage = menu.template === 'single_page';

  useEffect(() => {
    import('../../../javascript/js/sticky');
  }, [menu]);

  const onSearch = useCallback((term) => {
    import('alisto.js/lib/search').then((search) => {
      const results = search.default(term, products, ['name', 'description']).slice(0, 100);
      if (isSinglePage) {
        const ids = results.map((result) => result.id);
        document.querySelectorAll('div.category').forEach((section) => {
          let hasVisible = false;
          section.querySelectorAll('.item').forEach((item) => {
            const visible = term === '' || ids.includes(item.dataset.id);
            if (visible) hasVisible = true;
            item.parentElement.style.display = visible ? 'block' : 'none';
          });
          section.parentElement.style.display = hasVisible ? 'block' : 'none';
        });
      } else {
        setSearchedProducts(results);
      }
    });
  }, [isSinglePage, products]);

  const submit = () => {
    if (menu.disableAuthentication && !menu.userSignedIn) {
      setShowCustomerDialog(true);
    } else {
      onSubmit();
    }
  };

  return (
    <Router>
      <div className="row">
        <div className="col-lg-9 px-0 px-lg-2" data-sticky-container>
          <Header menu={menu} dispatch={dispatch} />
          {'Notification' in window && (
            <EnableNotifications />
          )}
          {menu.carouselImages.length > 0 && (
            <Carousel showArrows autoPlay showThumbs={false} showStatus={false}>
              {menu.carouselImages.map((image) => (
                <div key={image}>
                  <img src={image} alt="" />
                </div>
              ))}
            </Carousel>
          )}
          {isSinglePage && (
            <Navigation categories={categories} />
          )}
          <div className="px-3 pt-3 mb-4 pb-2">
            <SearchInput placeholder={menu.texts.search} onKeyUp={onSearch} />
          </div>
          {searchedProducts.length === 0 ? (
            <Grid container spacing={1} className="container" alignItems="stretch">
              {selectedCategory === null ? categories.map((category) => (
                <Category
                  key={category.slug}
                  category={category}
                  dispatch={dispatch}
                  menu={menu}
                  isSinglePage={isSinglePage}
                />
              )) : (
                <Grid item xs={12}>
                  <CategorySection
                    category={selectedCategory}
                    dispatch={dispatch}
                    menu={menu}
                    showGoBack
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={1}>
              {searchedProducts.map((product) => (
                <Grid item lg={6} style={{ display: 'flex', width: '100%' }} key={product.id}>
                  <Product
                    key={product.id}
                    product={product}
                    dispatch={dispatch}
                    menu={menu}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
        {menu.available && (
          <div id="shopping-cart-container" className="col-lg-3 px-0 px-lg-2" data-sticky-container>
            <Paper id="shopping-cart" className="py-lg-4 px-lg-3 sticky">
              <Suspense fallback={<div>Loading</div>}>
                <ShoppingCart
                  onSubmit={submit}
                  minOrder={menu.minOrder}
                  restaurantAddress={menu.address}
                  {...components.shoppingCart}
                />
              </Suspense>
            </Paper>
          </div>
        )}
      </div>
      <Suspense fallback={<div>Loading</div>}>
        <ItemDetailsWithNavigation
          showCover
          navigateBackToPath="/home"
          readonly={menu.readonly}
        />
        <AddressFormFromState
          {...components.addressForm}
          city={menu.city}
          country={menu.country}
          addresses={menu.addresses}
          googleMapsKey={menu.googleMapsKey}
          enableCurrentPosition={menu.enableCurrentPosition}
          enableAutocompleteAddressInput={menu.enableAutocompleteAddressInput}
          enableZipcodeInput={menu.enableZipcodeInput}
          freightType={menu.freightType}
          isSignedIn={isSignedIn}
          minWait={menu.minWait}
          maxWait={menu.maxWait}
          open={menu.open}
          servesLocal={menu.servesLocal}
          showMap
          latitude={menu.latitude}
          longitude={menu.longitude}
          radius={menu.maxDistance}
        />
        {menu.adultsOnly && <AdultsGate {...components.adultsGate} />}
        <PWAPrompt
          copyTitle={i18n.pwa.prompt.ios.title}
          copyBody={i18n.pwa.prompt.ios.body}
          copyShareButtonLabel={i18n.pwa.prompt.ios.share}
          copyAddHomeButtonLabel={i18n.pwa.prompt.ios.home}
          copyClosePrompt="Cancelar"
        />
      </Suspense>
      <CustomerDialog
        open={showCustomerDialog}
        onHide={() => setShowCustomerDialog(false)}
        onSubmit={onSubmit}
        phoneMask={menu.phoneMask}
      />
      <CookieConsent
        buttonText={i18n.accept}
        buttonClasses="btn btn-primary btn-raised mb-3"
        buttonStyle={{}}
        buttonWrapperClasses="container-fluid text-right"
        disableButtonStyles
      >
        {i18n.cookieBanner}
      </CookieConsent>
      <Routes>
        <Route
          path="/"
          element={
            <ChooseChannel dispatch={dispatch} table={menu.table} />
          }
        />
        <Route path="/home" element={null} />
        <Route
          path={`/${i18n.routes.categories}`}
          element={
            <ShowCategories setSelectedCategory={setSelectedCategory} />
          }
        />
        <Route
          path={`/${i18n.routes.categories}/:slug`}
          element={(
            <ShowCategory
              categories={categories}
              setSelectedCategory={setSelectedCategory}
              isSinglePage={isSinglePage}
            />
          )}
        />
        <Route
          path={`/${i18n.routes.products}/:slug`}
          element={
            <ShowProduct dispatch={dispatch} products={products} />
          }
        />
      </Routes>
    </Router>
  );
};

export default Menu;
