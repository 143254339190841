import React from 'react';
import { useNavigate } from 'react-router-dom';
import ItemDetails from './ItemDetails';

const ItemDetailsWithNavigation = function (props) {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(props.navigateBackToPath);
  };

  return <ItemDetails {...props} onFinish={navigateBack} />;
};

export default ItemDetailsWithNavigation;
