import React from 'react';
import Grid from '@material-ui/core/Grid';
import isVisible from 'alisto.js/lib/is-visible';
import Product from './Product';

const CategorySectionColumn = function ({
  category, dispatch, menu,
}) {
  return (
    <Grid container spacing={1}>
      {category.products.filter(isVisible).map((product) => (
        <Grid item lg={6} style={{ display: 'flex', width: '100%' }} key={product.id}>
          <Product
            key={product.id}
            product={product}
            dispatch={dispatch}
            menu={menu}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo(CategorySectionColumn);
