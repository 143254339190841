import React from 'react';
import ItemDetailsContainer from 'alisto.js/lib/components/ItemDetailsContainer';
import i18n from '../../../javascript/js/i18n';
import ItemDetailsContent from './ItemDetailsContent';

const ItemDetails = function ({
  enableNumericInputs = false,
  showCover = false,
  onFinish = null,
  readonly = false,
}) {
  return (
    <ItemDetailsContainer
      i18n={i18n}
      enableNumericInputs={enableNumericInputs}
      showCover={showCover}
      onFinish={onFinish}
      readonly={readonly}
      Content={ItemDetailsContent}
    />
  );
};

export default ItemDetails;
