import React from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import { cartTotal } from 'alisto.js/lib/item-total';
import action from 'alisto.js/lib/action';
import toCurrency from 'alisto.js/lib/to-currency';
import round from 'alisto.js/lib/round';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import makeButton from '../lib/make-button';
import AddressInput from './AddressInput';
import 'core-js/features/array/flat-map';
import ClickOnceButton from './ClickOnceButton';
import Items from './shopping_cart/Items';
import Total from './shopping_cart/Total';
import Payment from './pdv/Payment';
import i18n from '../../../javascript/js/i18n';

const isMissingAddress = (deliveryMethod, address) => deliveryMethod === 'delivery' && address === null;

const isConfirmable = (state) => state.items.length > 0
  && (['platform', 'pdv'].includes(state.channel))
  && !isMissingAddress(state.deliveryMethod, state.confirmedAddress)
  && (
    state.channel === 'platform'
    || (state.missingPayment !== null && round(state.missingPayment, 2) === 0)
    || (state.cardCode !== null || state.table !== null)
  );

const mapStateToProps = (state) => ({
  address: state.confirmedAddress,
  canConfirm: isConfirmable(state),
  subtotal: state.subtotal,
  deliverAutomatically: state.deliverAutomatically,
  discountType: state.discountType,
  discount: state.discount,
  discountPercentage: state.discountPercentage,
  displayDeliverInput: state.displayDeliverInput,
  displayMissingPayment: state.displayMissingPayment && state.deliveryMethod !== 'served',
  donation: state.donation,
  freightPrice: state.freightPrice,
  serviceFee: state.serviceFee,
  isCollapsed: state.isCollapsed,
  items: state.items,
  table: state.table,
  deliveryMethod: state.deliveryMethod,
  missingPayment: state.missingPayment,
  cardCode: state.cardCode,
  change: state.change,
  channel: state.channel,
  paymentDiscount: state.paymentDiscount,
  promotionDiscount: state.promotionDiscount,
});

const mapDispatchToProps = (dispatch) => ({
  onCancel: () => dispatch(action('CANCEL')),
  onCollapse: () => dispatch(action('COLLAPSE')),
  onDeliverAutomaticallyChange: (deliverAutomatically) => dispatch(action('SET_DELIVER_AUTOMATICALLY', deliverAutomatically)),
  onEditAddress: () => dispatch(action('EDIT_ADDRESS')),
  onEditItem: (index) => dispatch(action('EDIT_ITEM', index)),
  onRemoveItem: (index) => dispatch(action('REMOVE_ITEM', index)),
  onUncollapse: () => dispatch(action('UNCOLLAPSE')),
});

const ShoppingCart = function ({
  addAddressLabel,
  address,
  cardCode,
  channel,
  items,
  restaurantAddress,
  subtotal,
  discountType,
  discount,
  discountPercentage,
  displayDeliverInput,
  displayMissingPayment,
  donation,
  freightPrice,
  serviceFee,
  deliverAutomatically,
  onDeliverAutomaticallyChange,
  onRemoveItem,
  onEditAddress,
  onEditItem,
  onSubmit,
  onCancel,
  canConfirm,
  confirmLabel,
  isCollapsed,
  onCollapse,
  onUncollapse,
  paymentDiscount,
  promotionDiscount,
  removeLabel,
  shippingAddressTitle,
  shippingRestaurantTitle,
  shippingTableTitle,
  orderTitle,
  table,
  onEmptyMessage,
  openCartLabel,
  cancelMessage,
  minOrder,
  minOrderLabel,
  deliveryMethod,
  missingPayment,
  change,
  onAddPayment,
  paymentMethods,
}) {
  const hasItems = items.length > 0;

  const theme = useTheme();
  const ErrorButton = makeButton(theme.palette.error);
  const SuccessButton = makeButton(theme.palette.success);

  const remove = (index, event) => {
    event.preventDefault();
    onRemoveItem(index);
  };

  const total = cartTotal({
    items,
    subtotal,
    freightPrice,
    serviceFee,
    discountType,
    discount,
    discountPercentage,
    donation,
    paymentDiscount,
    promotionDiscount,
  });
  const underMinOrder = minOrder && subtotal < minOrder;
  const canSubmit = canConfirm && !underMinOrder;

  const submit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return onSubmit();
  };

  const cancel = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onCancel();
  };

  const unconfirmedFeedback = () => {
    if (underMinOrder) {
      return (
        <div className="bg-info text-white w-100 rounded p-3">
          {`${minOrderLabel}: ${toCurrency(minOrder)}`}
        </div>
      );
    } if (isMissingAddress(deliveryMethod, address)) {
      return (
        <ErrorButton
          variant="contained"
          className="inform-address"
          onClick={onEditAddress}
          fullWidth
        >
          {i18n.informAddress}
        </ErrorButton>
      );
    }
    return '';
  };

  const restaurantAddressInfo = (title) => (
    <>
      <small className="d-block">{title}</small>
      <div className="pt-1 d-flex align-content-center">
        <Button
          color="primary"
          className="p-0 m-0 d-flex"
          onClick={onEditAddress}
          style={{ lineHeight: '1.5em' }}
        >
          <i className="fas fa-fw add-address fa-map-marker-alt mr-2 align-self-center" />
          <div className="text-left">{restaurantAddress}</div>
        </Button>
      </div>
    </>
  );

  const showCart = hasItems || address !== null || table;

  return (
    <>
      <Collapse in={!isCollapsed}>
        <div className="close-shopping-cart">
          <Button
            variant="contained"
            color="primary"
            className="py-3 px-3 rounded-0 d-lg-none"
            disableElevation
            fullWidth
            onClick={onCollapse}
            tabIndex={0}
          >
            Continuar comprando
            <i className="fa fa-chevron-down ml-2" />
          </Button>
          <div className="p-3 p-lg-0" style={{ backgroundColor: 'white' }}>
            {showCart ? (
              <>
                <div className="mb-3 shopping-cart-address">
                  {deliveryMethod === 'pickup' && channel !== 'pdv' && restaurantAddressInfo(shippingRestaurantTitle)}
                  {deliveryMethod === 'consume_at_place' ? restaurantAddressInfo(i18n.consumeAtPlace) : ''}
                  <div className="d-flex">
                    {deliveryMethod === 'served' && table && (
                      <div className="mr-2">
                        <small>{shippingTableTitle}</small>
                        <div>{table.name}</div>
                      </div>
                    )}
                    {cardCode && (
                      <div>
                        <small>{i18n.card}</small>
                        <div>{cardCode}</div>
                      </div>
                    )}
                  </div>
                  {deliveryMethod === 'delivery' && (
                    <>
                      <small className="d-block">{shippingAddressTitle}</small>
                      <div className="pt-1 d-flex align-content-center">
                        <AddressInput
                          address={address}
                          onEdit={onEditAddress}
                          editLabel={addAddressLabel}
                        />
                      </div>
                      {address?.maxWait !== undefined && (
                        <>
                          <small className="d-block mt-3">{i18n.waitTime}</small>
                          <div className="pt-1 d-flex align-content-center">
                            <i className="fa fa-fw fa-clock mr-2 align-self-center" />
                            {i18n.waitTimeValue(address.maxWait)}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <h5 className="mb-2">{orderTitle}</h5>
                <Items
                  items={items}
                  onEditItem={onEditItem}
                  onRemoveItem={remove}
                  removeLabel={removeLabel}
                />
                <Total
                  subtotal={subtotal}
                  deliveryMethod={deliveryMethod}
                  discountType={discountType}
                  discount={discount}
                  discountPercentage={discountPercentage}
                  donation={donation}
                  address={address}
                  freightPrice={freightPrice}
                  serviceFee={serviceFee}
                  total={total}
                  missingPayment={missingPayment}
                  displayMissingPayment={displayMissingPayment}
                  change={change}
                  paymentDiscount={paymentDiscount}
                  promotionDiscount={promotionDiscount}
                />
                {displayDeliverInput && (
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={deliverAutomatically}
                        onChange={(e) => onDeliverAutomaticallyChange(e.target.checked)}
                        color="primary"
                        style={{
                          padding: '9px',
                          position: 'absolute',
                          right: 'auto',
                        }}
                      />
                    )}
                    label="Marcar como entregue"
                    className="mb-4"
                  />
                )}
                {deliveryMethod !== 'served' && onAddPayment && (
                  <Payment
                    missingPayment={missingPayment}
                    onPayment={onAddPayment}
                    paymentMethods={paymentMethods}
                    fullWidth
                  />
                )}
                {canSubmit ? (
                  <ClickOnceButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="submit-shopping-cart"
                    onClick={submit}
                    fullWidth
                    disableElevation
                  >
                    {confirmLabel}
                    <i
                      className="fa fa-chevron-right ml-auto"
                      style={{
                        position: 'absolute',
                        right: '20px',
                      }}
                    />
                  </ClickOnceButton>
                ) : unconfirmedFeedback()}
              </>
            ) : (
              <div className="text-center py-4 text-muted">
                {onEmptyMessage}
              </div>
            )}
          </div>
        </div>
      </Collapse>
      {isCollapsed && hasItems && (
        <div
          role="button"
          className="open-shopping-cart font-weight-bold p-3 d-flex justify-content-between align-items-center"
          onClick={onUncollapse}
          tabIndex={0}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          <div id="shopping-cart-footer-cancel-container">
            <ErrorButton variant="contained" className="cancel" onClick={cancel}>
              {cancelMessage}
            </ErrorButton>
          </div>
          <div id="shopping-cart-footer-open_cart-container">
            <span className="mr-2">{openCartLabel}</span>
            <i className="fa fa-chevron-up" />
          </div>
          <div className="total">{toCurrency(total)}</div>
          {canSubmit && (
            <div id="shopping-cart-footer-submit-container">
              <SuccessButton type="submit" variant="contained" className="confirm" onClick={submit}>
                {confirmLabel}
              </SuccessButton>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
