import React from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import isVisible from 'alisto.js/lib/is-visible';
import Product from './Product';
import useDrag from '../useDrag';

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

const CategorySectionRow = function ({
  category, dispatch, menu,
}) {
  const {
    dragStart, dragStop, dragMove,
  } = useDrag();

  const handleDrag = ({ scrollContainer }) => (ev) => dragMove(ev, (posDiff) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += posDiff;
    }
  });

  return (
    <div className="row d-block">
      <ScrollMenu
        onWheel={onWheel}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
      >
        {category.products.filter(isVisible).map((product) => (
          <Product
            key={product.id}
            product={product}
            dispatch={dispatch}
            menu={menu}
            direction="column"
            width="170px"
            productOrder="0"
            coverMarginLeft="0"
            coverWidth="100%"
            coverHeight="auto"
            coverMarginBottom="5px"
          />
        ))}
      </ScrollMenu>
    </div>
  );
};

export default React.memo(CategorySectionRow);
