import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

const ClickOnceButton = function ({
  color,
  onClick,
  disabled,
  children,
  variant,
  type,
  className,
  fullWidth,
  disableElevation,
  classes,
}) {
  const [enabled, setEnabled] = useState(true);

  const click = (e) => {
    setEnabled(false);
    const result = onClick(e);
    if (result instanceof Promise) {
      result.then(() => setEnabled(true));
    }
  };

  return (
    <Button
      type={type}
      color={color}
      variant={variant}
      className={className}
      onClick={click}
      fullWidth={fullWidth}
      disableElevation={disableElevation}
      disabled={!enabled || disabled}
      classes={classes}
    >
      {children}
    </Button>
  );
};

export default React.memo(ClickOnceButton);
