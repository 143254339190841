import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import i18n from '../../../javascript/js/i18n';
import notification from '../lib/notification';
import 'core-js/features/array/flat';

const EnableNotifications = function () {
  const [isVisible, setIsVisible] = useState(Notification.permission === 'default');

  const enableNotifications = () => {
    notification('/users/push_tokens').then((permission) => {
      setIsVisible(permission === 'default');
    });
  };

  return isVisible ? (
    <Paper className="my-3 p-3">
      <div>
        <h5>{i18n.enableNotifications}</h5>
        <p>{i18n.enableNotificationsDescription}</p>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={enableNotifications}
        >
          <i className="fa fa-bell mr-2" />
          {i18n.activate}
        </Button>
      </div>
    </Paper>
  ) : '';
};

export default EnableNotifications;
